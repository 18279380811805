import React from "react";

const FooterLower = () => {
  return (
    <div>
      <p
        style={{
          textAlign: "center",
          color: "white",
          background: "black",
          padding: "5px 0 ",
        }}
      >
        &#169;2024, Atraski India, Atstay, All right reserved
      </p>
    </div>
  );
};

export default FooterLower;
